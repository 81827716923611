<template>

<div>

  <document-entry :document-section="1" />

  <documents :document-section="1"></documents>

</div>

</template>

<script>
import Documents from '@/components/Documents';
import DocumentEntry from '@/components/DocumentEntry';

export default {
  name: 'DocumentsList',
  components: {
    Documents,
    DocumentEntry,
  },
};
</script>

<style scoped>

</style>
